import { Image } from "@sizdevteam1/funjoiner-uikit";

import FunJoinerLogo from "../../assets/imgs/fj_logo_sm.svg";
import React from "react";
import useStores from "../../hooks/useStores";
import PageRoot from "../../components/PageRoot";
import PageBlock from "../../components/PageBlock/PageBlock";
import cn from "classnames";
import formatPhone from "../../util/formatPhone";

export default function CompanyLockedPage() {
  const { commonStore } = useStores();
  return (
    <div className={"flex flex-col items-center"}>
      <Image
        className={"mt-[14px] !h-[80px] !w-[80px]"}
        src={commonStore.companyProfile?.logo || FunJoinerLogo}
        alt={commonStore.companyProfile.name}
      />
      <PageRoot>
        <PageBlock className={"mt-8 flex flex-col items-center"}>
          <i className={"icon thinking-sun-icon !h-[90px] !w-[90px]"} />
          <div className="typography-h1 mt-2 text-center text-text-color">
            {commonStore.companyProfile.name}
          </div>
          <div className="typography-h2 mt-1 flex gap-1 text-text-color">
            <i
              className={"icon lock-filled-icon bg-surface-orange-dark-color"}
            />{" "}
            Locked
          </div>
          <div className="typography-main mt-2 text-center text-text-color">
            Please reach out to the business directly, because this account is
            currently unavailable.
          </div>
        </PageBlock>
        <PageBlock className={"mt-4 flex flex-col gap-2"}>
          <div className={"flex gap-3"}>
            <i className={"icon mobile-icon bg-main-color"} />{" "}
            <a
              className={cn("text-main text-gray")}
              href={`tel:${commonStore.companyProfile.phone}`}
            >
              {formatPhone(commonStore.companyProfile.phone)}
            </a>
          </div>
          <div className={"flex gap-3"}>
            <i className={"icon emails-icon bg-main-color"} />{" "}
            <a
              className={cn("text-main text-gray")}
              href={`mailto:${commonStore.companyProfile.email}`}
            >
              {formatPhone(commonStore.companyProfile.email)}
            </a>
          </div>
        </PageBlock>
      </PageRoot>
    </div>
  );
}
