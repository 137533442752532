import apiService from "./apiService";
import { IStorageFileDTO } from "./files";
import { Cents, ISOString } from "@sizdevteam1/funjoiner-uikit/types";

export interface IPaginationParams {
  limit: number;
  offset: number;
}
export interface IPagination<T> {
  total: number;
  offset: number;
  has_more: boolean;
  items: T[];
}

export interface ILocationDTO {
  id: number;
  name: string;
  description: string;
  address: string;
  directions: string;
  is_archived: boolean;
  photo?: string;
  folder: ILocationFolderDTO | null;
}

export interface ILocationFolderDTO {
  id: string;
  name: string;
}

export interface ICompanyProfileDTO {
  name: string;
  email: string;
  phone: string;
  logo?: string;
  website: string;
  social: {
    facebook: string;
    pinterest: string;
    instagram: string;
    youtube: string;
    twitter: string;
  };
  documents: {
    cancellation_policy: string;
    terms_of_service: string;
    payment_plan_policy: string | null;
    application_policy: string;
  };
}

export type BaselineQuestion = {
  status: "required" | "optional" | "disabled";
};

export interface ICustomerHubMessage {
  enabled: boolean;
  text: string;
}

export interface IWelcomeMessageSettingsDTO {
  is_enabled: boolean;
  default_text: string;
  by_funbox_and_location_messages: ByFunboxAndLocationWelcomeMessageSettingsDTO[];
}
export interface ByFunboxAndLocationWelcomeMessageSettingsDTO {
  funbox_id: string;
  location_id: number;
  state: "off" | "default" | "custom";
  custom_message: string;
}
export type SignInPageView = "TABS" | "PRIORITIZE_PHONE";
export interface PublicSettings {
  credits: ICompanyCreditSettings;
  customer_hub_settings: {
    sign_in_page_view: SignInPageView;
    attention_message: ICustomerHubMessage;
    dashboard_message: ICustomerHubMessage;
    welcome_message_settings: IWelcomeMessageSettingsDTO;
    baseline_questions: {
      for_participants: {
        pronoun: BaselineQuestion;
        gender: BaselineQuestion;
      };
      for_customers: {
        address: BaselineQuestion;
        required_emergency_contacts: number;
      };
    };
  };
}

export interface ICompanyCreditSettings {
  upgrades_by_customer: boolean;
}

export interface ICompanyCancelPolicy {
  is_enabled: boolean;
  hours_before_program_start: number;
  hours_before_session_start: number;
}

export interface ICompanyReschedulePolicy {
  is_enabled: boolean;
  hours_before_program_start: number;
  hours_before_session_start: number;
}

export interface ICompanySchedulePolicy {
  hours_before_program_start: number;
  hours_before_session_start: number;
}
export type IPriceModifierDTO = {
  min_quantity: number;
} & (
  | {
      discount_percent: number;
      discount_flat_amount: undefined;
    }
  | {
      discount_percent: undefined;
      discount_flat_amount: number;
    }
);

export type CreditPackageDTO = {
  number_of_credits: number;
} & (
  | {
      discount_percent: number;
      discount_flat_amount: undefined;
    }
  | {
      discount_percent: undefined;
      discount_flat_amount: number;
    }
);

export interface ICreditTypeDTO {
  id: number;
  name: string;
  description: string;
  price: number;
  is_free: boolean;
  is_program_credit: boolean;
  is_hidden_from_flexible_payments_page: boolean;
  active_program_type_ids: number[];
  modifiers: IPriceModifierDTO[];
  packages: CreditPackageDTO[];
  upgrade_options: ICreditTypeUpgradeOption[];
}

export interface ICreditTypeUpgradeOption {
  to_credit_type_id: number;
  price: Cents;
}

export interface IAvailableSession {
  id: number;
  location_id: number;
  program_set_id: number;
  program_set_name: string;
  program_id: number;
  program_type_id: number;
  program_type_name: string;
  date: ISOString;
  start_time: string;
  end_time: string;
  available_spots?: number;
  is_locked: boolean;
}

export interface ICheckAvailabilityParams {
  date_from: string;
  date_to: string;
  location_id: number;
  ignore_set_up_credits?: boolean;
}

export type TFunboxType =
  | "day_camps"
  | "lessons"
  | "events"
  | "memberships"
  | "equipment_rentals"
  | "custom"
  | "overnight_camps"
  | "classes"
  | "online_courses"
  | "teams"
  | "leagues"
  | "child_care"
  | "schools"
  | "virtual_library"
  | "tours";

export type FunboxDisplayOption = "lineup" | "hidden";
export type FunboxMode = "SESSIONS" | "PROGRAMS" | "PROGRAMS_AND_SESSIONS";
export type BothModeFirstOption = "sessions" | "programs";
export type ParticipantsOrCustomers = "PARTICIPANTS" | "CUSTOMERS";

export interface InvoicePaymentSettings {
  is_enabled: boolean;
  description: string;
}
export interface CreditsSettings {
  schedule_policy: SchedulePolicy;
  reschedule_policy: ReschedulePolicy;
  cancel_policy: CancelPolicy;
}
export interface SchedulePolicy {
  hours_before_program_start: number;
  hours_before_session_start: number;
}
export interface ReschedulePolicy {
  is_enabled: boolean;
  hours_before_program_start: number;
  hours_before_session_start: number;
}
export interface CancelPolicy {
  is_enabled: boolean;
  hours_before_program_start: number;
  hours_before_session_start: number;
}

export type BuyCreditsAndSaveTab = "CREDITS" | "PACKAGES";
export type BuyCreditsAndSaveTabSettings = {
  is_enabled: boolean;
  name: string;
};

export interface IFunboxDTO {
  id: string;
  url_part?: string;
  name: string;
  description: string;
  requirements: string;
  type: TFunboxType;
  active_locations: ILocationDTO[];
  active_program_types: IProgramTypeDTO[];
  display_option: FunboxDisplayOption;
  mode: FunboxMode;
  customer_hub_visible_mode: FunboxMode;
  both_mode_first_option: BothModeFirstOption;

  is_buy_credits_and_save_enabled: boolean;
  buy_credits_and_save_first_tab: BuyCreditsAndSaveTab;
  buy_credits_and_save_credits_tab: BuyCreditsAndSaveTabSettings;
  buy_credits_and_save_packages_tab: BuyCreditsAndSaveTabSettings;

  sessions_option_name: string;
  programs_option_name: string;
  photo_file: IStorageFileDTO | null;
  photo?: string;
  thumbnail_file: IStorageFileDTO | null;
  thumbnail?: string;
  participants_or_customers: ParticipantsOrCustomers;
  paid_credits_settings: CreditsSettings;
  free_credits_settings: CreditsSettings;
  invoice_payment_settings: InvoicePaymentSettings;
  all_schedules_require_payment_information: boolean;
  block_conflicting_schedules_for_different_offerings: boolean;
}

export interface IProgramTypeDTO {
  id: number;
  name: string;
  description: string;
  redeemable_for: number[];
  is_archived: boolean;
  funbox_ids: string[];
  from_age: number | null;
  to_age: number | null;
  photo_file: IStorageFileDTO | null;
  thumbnail_file: IStorageFileDTO | null;
  is_hidden_from_explore_offerings_page: boolean;
}

export const locations = (): Promise<ILocationDTO[]> =>
  apiService.get("/customer_api/v2/locations/", { authorization: false });

export const creditTypes = (): Promise<ICreditTypeDTO[]> =>
  apiService.get(`/customer_api/v2/credit_types/`, {
    authorization: false,
  });

export const companyProfile = (): Promise<ICompanyProfileDTO> =>
  apiService.get("/customer_api/v2/company_profile", { authorization: false });

export const companySettings = (): Promise<PublicSettings> =>
  apiService.get("/customer_api/v2/public_settings", {
    authorization: false,
  });

export const funboxes = (): Promise<IFunboxDTO[]> =>
  apiService.get("/customer_api/v2/funboxes/", { authorization: false });

export const funboxById = (id: string): Promise<IFunboxDTO> =>
  apiService.get(`/customer_api/v2/funboxes/${id}`, { authorization: false });

export const funboxByUrlPart = (url_part: string): Promise<IFunboxDTO> =>
  apiService.get(`/customer_api/v2/funboxes/get_by_url_part/${url_part}`, {
    authorization: false,
  });

export const funboxCreditTypes = (
  funboxId: string,
): Promise<ICreditTypeDTO[]> =>
  apiService.get(`/customer_api/v2/funboxes/${funboxId}/credit_types/`, {
    authorization: false,
  });

export const locationFolders = (): Promise<ILocationFolderDTO[]> =>
  apiService.get(`/customer_api/v2/locations/folders/`, {
    authorization: false,
  });

export interface InitRequestData {
  is_company_locked: boolean;
  company_profile: ICompanyProfileDTO;
  locations: ILocationDTO[];
  credit_types: ICreditTypeDTO[];
  funboxes: IFunboxDTO[];
  public_settings: PublicSettings;
}
export const initRequest = () =>
  apiService.get<InitRequestData>("/customer_api/v2/init_request", {
    authorization: false,
  });
